<template>
  <div>

    <v-dialog v-model="dialogDelete" max-width="400">
      <v-card>
        <v-card-title>{{ $t('confirm_generic') }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="info" text @click="dialogDelete = false">{{ $t('button_cancel') }}
          </v-btn>
          <v-btn color="warning" text @click="deleteStep">{{ $t('button_yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col cols="12" class="d-flex align-center pr-2">
        <v-select
            v-model="currentStep.type"
            :items="stepTypes"
            item-value="type"
            item-text="label"
            outlined
            :disabled="currentStep.type_lock"
            hide-details
            dense
            :label="$t('type')"
            @change="stepChanged(true)"
            class="mr-4"
        ></v-select>
        <v-switch
            v-model="currentStep.auto"
            :disabled="!['verification', 'screening', 'saq', 'risk-analysis'].includes(currentStep.type)"
            hide-details
            :label="$t('automated')"
            class="mr-4"
            @change="stepChanged(false)"
        ></v-switch>
        <v-switch
            v-model="currentStep.type_lock"
            hide-details
            :label="$t('type_locked')"
            class="mr-4"
            @change="stepChanged(false)"
        ></v-switch>
        <v-switch
            v-model="currentStep.manual"
            hide-details
            :label="$t('manual')"
            class="mr-4"
            @change="stepChanged(false)"
        ></v-switch>
        <v-switch
            v-model="currentStep.specific"
            :disabled="currentStep.type === 'check'"
            hide-details
            :label="$t('workflow_specific')"
            class="mr-4"
            @change="stepChanged(false)"
        ></v-switch>
        <v-switch
            v-model="currentStep.obsolete"
            hide-details
            :label="$t('obsolete')"
            class="mr-4"
            @change="stepChanged(false)"
        ></v-switch>
        <v-switch
            v-model="currentStep.locked"
            hide-details
            :label="$t('locked')"
            class="mr-4"
            @change="stepChanged(false)"
        ></v-switch>
        <v-btn icon @click.stop="dialogDelete = true">
          <v-icon>{{ icons.mdiDelete }}</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
            v-model.trim="currentStep.labels.en"
            outlined
            dense
            :label="$t('label_en')"
            hide-details
            @change="stepChanged(false)"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
            v-model.trim="currentStep.labels.sv"
            outlined
            dense
            :label="$t('label_sv')"
            hide-details
            @change="stepChanged(false)"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
            v-model.trim="currentStep.labels.no"
            outlined
            dense
            :label="$t('label_no')"
            hide-details
            @change="stepChanged(false)"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
            v-model.trim="currentStep.labels.da"
            outlined
            dense
            :label="$t('label_da')"
            hide-details
            @change="stepChanged(false)"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
            v-model.trim="currentStep.labels.fi"
            outlined
            dense
            :label="$t('label_fi')"
            hide-details
            @change="stepChanged(false)"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-switch
            v-model="currentStep.set_active"
            hide-details
            :label="$t('change_supplier_status_new_to_active')"
            class="mr-4"
            @change="stepChanged(false)"
        ></v-switch>
      </v-col>
      <v-col cols="6">
        <v-select
            v-model="currentStep.completes"
            :items="steps.filter(i => i.id !== currentStep.id)"
            item-value="id"
            :item-text="i => i.labels.en || i.type"
            outlined
            multiple
            small-chips
            dense
            :label="$t('also_completes')"
            hide-details
            @change="stepChanged(false)"
        >
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
            v-model="currentStep.filters.risk.values"
            :items="ratings.filter(i => i.value > 0)"
            outlined
            multiple
            small-chips
            dense
            :label="$t('risk_filter')"
            hide-details
            @change="stepChanged(false)"
        >
        </v-select>
        <v-switch v-model="currentStep.filters.risk.excluding" :label="$t('excluding')"
                  @change="stepChanged(false)"></v-switch>
        <v-select
            v-model="currentStep.filters.risk.models"
            :items="entities.riskAnalysisModels"
            outlined
            multiple
            dense
            :label="$t('filter_models')"
            hide-details
            @change="stepChanged(false)"
        ></v-select>
      </v-col>
      <v-col>
        <v-select
            v-model="currentStep.filters.status.values"
            :items="statuses"
            outlined
            multiple
            small-chips
            dense
            :label="$t('status_filter')"
            hide-details
            @change="stepChanged(false)"
        >
        </v-select>
        <v-switch v-model="currentStep.filters.status.excluding" :label="$t('excluding')" hide-details
                  @change="stepChanged(false)"></v-switch>
      </v-col>
      <v-col>
        <v-select
            v-model="currentStep.filters.rating.values"
            :items="ratings.filter(i => i.value < 4)"
            outlined
            multiple
            small-chips
            dense
            :label="$t('rating_filter')"
            hide-details
            @change="stepChanged(false)"
        >
        </v-select>
        <v-switch v-model="currentStep.filters.rating.excluding" :label="$t('excluding')" hide-details
                  @change="stepChanged(false)"></v-switch>
      </v-col>
      <v-col>
        <v-autocomplete
            v-model="currentStep.filters.category.values"
            :items="entities.categories"
            item-text="text"
            item-value="value"
            outlined
            multiple
            small-chips
            dense
            :label="$t('category_filter')"
            hide-details
            @change="stepChanged(false)"
        >
        </v-autocomplete>
        <v-switch v-model="currentStep.filters.category.excluding" :label="$t('excluding')" hide-details
                  @change="stepChanged(false)"></v-switch>
      </v-col>
      <v-col>
        <v-autocomplete
            v-model="currentStep.filters.group.values"
            :items="entities.userGroups"
            item-text="text"
            item-value="value"
            outlined
            multiple
            small-chips
            dense
            :label="$t('group_filter')"
            hide-details
            @change="stepChanged(false)"
        >
        </v-autocomplete>
        <v-switch v-model="currentStep.filters.group.excluding" :label="$t('excluding')" hide-details
                  @change="stepChanged(false)"></v-switch>
      </v-col>
    </v-row>
    <v-row v-if="currentStep.type === 'verification' || currentStep.type === 'screening' || currentStep.type === 'risk-analysis'">
      <v-col cols="6" v-if="currentStep.type !== 'risk-analysis'">
        <v-switch v-model="currentStep.enable_stopper" :label="$t('enable_stopper')"
                  @change="stepChanged(false)"></v-switch>
        <v-select
            v-if="currentStep.type === 'screening'"
            v-model="currentStep.stopper_threshold"
            outlined
            dense
            :items="screeningThresholds"
            :label="$t('stopper_threshold')"
            hide-details
            @change="stepChanged(false)">
        </v-select>
      </v-col>
      <v-col cols="6">
        <v-switch v-model="currentStep.enable_escalation" :label="$t('enable_escalation')"
                  @change="stepChanged(false)"></v-switch>
        <v-select
            v-if="currentStep.type === 'screening'"
            v-model="currentStep.escalation_criteria"
            multiple
            outlined
            dense
            :items="screeningThresholds.filter(i => i.value > 0)"
            :label="$t('escalation_criteria')"
            hide-details
            @change="stepChanged(false)">
        </v-select>
        <v-select
            v-if="currentStep.type === 'verification'"
            v-model="currentStep.escalation_criteria"
            multiple
            outlined
            dense
            :items="escalationCriteria.filter(i => i.type === 'verification')"
            :label="$t('escalation_criteria')"
            hide-details
            @change="stepChanged(false)">
        </v-select>
        <v-select
            v-if="currentStep.type === 'risk-analysis'"
            v-model="currentStep.escalation_criteria"
            multiple
            outlined
            dense
            :items="escalationCriteria.filter(i => i.type === 'risk-analysis')"
            :label="$t('escalation_criteria')"
            hide-details
            @change="stepChanged(false)">
        </v-select>
      </v-col>
    </v-row>
    <v-row v-if="currentStep.enable_stopper && (currentStep.type === 'verification' || currentStep.type === 'screening')">
      <v-col cols="6">
        <v-textarea
            v-model.trim="currentStep.stopper_texts.en"
            outlined
            :rows="2"
            dense
            :label="$t('stopper_text_en')"
            hide-details
            @change="stepChanged(false)"
        ></v-textarea>
      </v-col>
      <v-col cols="6">
        <v-textarea
            v-model.trim="currentStep.stopper_texts.sv"
            outlined
            :rows="2"
            dense
            :label="$t('stopper_text_sv')"
            hide-details
            @change="stepChanged(false)"
        ></v-textarea>
      </v-col>
      <v-col cols="6">
        <v-textarea
            v-model.trim="currentStep.stopper_texts.no"
            outlined
            :rows="2"
            dense
            :label="$t('stopper_text_no')"
            hide-details
            @change="stepChanged(false)"
        ></v-textarea>
      </v-col>
      <v-col cols="6">
        <v-textarea
            v-model.trim="currentStep.stopper_texts.da"
            outlined
            :rows="2"
            dense
            :label="$t('stopper_text_da')"
            hide-details
            @change="stepChanged(false)"
        ></v-textarea>
      </v-col>
      <v-col cols="6">
        <v-textarea
            v-model.trim="currentStep.stopper_texts.fi"
            outlined
            :rows="2"
            dense
            :label="$t('stopper_text_fi')"
            hide-details
            @change="stepChanged(false)"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row v-if="currentStep.enable_escalation && (currentStep.type === 'verification' || currentStep.type === 'screening')">
      <v-col cols="6">
        <v-textarea
            v-model.trim="currentStep.escalation_texts.en"
            outlined
            :rows="2"
            dense
            :label="$t('escalation_text_en')"
            hide-details
            @change="stepChanged(false)"
        ></v-textarea>
      </v-col>
      <v-col cols="6">
        <v-textarea
            v-model.trim="currentStep.escalation_texts.sv"
            outlined
            :rows="2"
            dense
            :label="$t('escalation_text_sv')"
            hide-details
            @change="stepChanged(false)"
        ></v-textarea>
      </v-col>
      <v-col cols="6">
        <v-textarea
            v-model.trim="currentStep.escalation_texts.no"
            outlined
            :rows="2"
            dense
            :label="$t('escalation_text_no')"
            hide-details
            @change="stepChanged(false)"
        ></v-textarea>
      </v-col>
      <v-col cols="6">
        <v-textarea
            v-model.trim="currentStep.escalation_texts.da"
            outlined
            :rows="2"
            dense
            :label="$t('escalation_text_da')"
            hide-details
            @change="stepChanged(false)"
        ></v-textarea>
      </v-col>
      <v-col cols="6">
        <v-textarea
            v-model.trim="currentStep.escalation_texts.fi"
            outlined
            :rows="2"
            dense
            :label="$t('escalation_text_fi')"
            hide-details
            @change="stepChanged(false)"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row v-if="currentStep.type === 'saq'">
      <v-col cols="4">
        <v-select
            v-model.trim="currentStep.saq_id"
            :items="entities.saqDefinitions"
            outlined
            dense
            :label="$t('saq')"
            hide-details
            @change="stepChanged(false)"
        ></v-select>
      </v-col>
      <v-col cols="4">
        <v-checkbox
            v-model.trim="currentStep.escalation.any_red"
            outlined
            dense
            :label="$t('escalation:any_red')"
            hide-details
            @change="stepChanged(false)"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row v-if="currentStep.type === 'risk-analysis'">
      <v-col cols="4">
        <v-select
            v-model="currentStep.model_id"
            :items="entities.riskAnalysisModels"
            outlined
            dense
            :label="$t('model')"
            hide-details
            @change="stepChanged(false)"
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="currentStep.type === 'site-visit'">
      <v-col cols="4">
        <v-select
            v-model="currentStep.checklist_id"
            :items="entities.siteVisitsChecklists"
            outlined
            dense
            :label="$t('checklist')"
            hide-details
            @change="stepChanged(false)"
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="currentStep.type === 'plan'">
      <v-col cols="4">
        <v-text-field
            v-model.trim="currentStep.plan_id"
            outlined
            dense
            :label="$t('plan')"
            hide-details
            @change="stepChanged(false)"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  mdiDelete,
  mdiDragVertical,
  mdiCircle,
  mdiInformation,
} from '@mdi/js';
import short from '@/shortuid';
import _cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'Step',
  props: {
    step: Object,
    steps: Array,
    entities: Object,
    index: Number,
  },
  created() {
    this.initStep();
  },
  data: () => ({
    dialogDelete: false,
    componentKey: 0,
    currentStep: {},
    linkLabel: null,
    ratings: [
      {text: 'Not rated', value: 0},
      {text: 'Green', value: 1},
      {text: 'Yellow', value: 2},
      {text: 'Red', value: 3},
      {text: 'Critical', value: 4},
    ],
    screeningThresholds: [
      {text: 'No threshold', value: 0},
      {text: 'Concern', value: 1},
      {text: 'Warning', value: 2},
      {text: 'Sanction', value: 3},
    ],
    escalationCriteria: [
      {type: 'verification', value: 'failed', text: 'Failed'},
      {type: 'verification', value: 'no_company_tax', text: 'No company tax registration'},
      {type: 'verification', value: 'no_financials', text: 'No company accounts'},
      {type: 'verification', value: 'losses', text: 'Losses'},
      {type: 'risk-analysis', value: 'red_flag', text: 'Red flag'},
    ],
    statuses: [
      {text: 'New', value: 'new'},
      {text: 'Prospect', value: 'prospect'},
      {text: 'Active', value: 'active'},
      {text: 'Inactive', value: 'inactive'},
      {text: 'Escalated', value: 'escalated'},
    ],
    stepTypes: [
      {
        type: 'risk-analysis',
        label: 'Risk analysis'
      },
      {
        type: 'verification',
        label: 'Verification'
      },
      {
        type: 'screening',
        label: 'Screening'
      },
      {
        type: 'saq',
        label: 'SAQ'
      },
      {
        type: 'site-visit',
        label: 'Site visit'
      },
      {
        type: 'event',
        label: 'Event'
      },
      {
        type: 'plan',
        label: 'Plan'
      },
      {
        type: 'check',
        label: 'Check'
      },
      {
        type: 'rating',
        label: 'Rating'
      },
    ],
    icons: {
      mdiDelete,
      mdiDragVertical,
      mdiCircle,
      mdiInformation,
    },
  }),
  methods: {
    deleteStep() {
      this.dialogDelete = false;
      this.$emit('delete', this.index);
    },
    initStep() {
      this.currentStep = _cloneDeep(this.step);
    },
    stepChanged(isTypeChange) {
      if (isTypeChange) {
        if (this.currentStep.type === 'check') {
          this.currentStep.specific = true;
        }
        this.currentStep.id = short.generateRandomBase();
      }
      this.$emit('changed', this.index, this.currentStep);
    }
  }
};
</script>

<style scoped>
.v-input--selection-controls {
  margin-top: 0;
}
</style>
